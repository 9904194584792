<script>
  let name = "Hello world";
</script>

<main>
  <div class="flex-container wrapper">
    <section class="content">
        {name}
    </section>

    <aside class="sidebar">
        Sidebar                
        <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sumenda potius quam expetenda. Nihil opus est exemplis hoc facere longius. At Zeno eum non beatum modo, sed etiam divitem dicere ausus est. Nunc ita separantur, ut disiuncta sint, quo nihil potest esse perversius. Itaque quantum adiit periculum!
        </p>
    </aside>

    
</div>
</main>
